import React, { useState, useEffect, useRef } from 'react';
import { graphql } from 'gatsby';
import {
  Layout,
  SEO,
  CJumbotron,
  CBreadCrumb,
  LWrap,
  CFloorList,
  CFacilityCard,
  CTabBtnList,
  CFixedImg,
  LWifi,
  ConceptMedia,
  LContact,
  CSectTitle,
  CNewsList,
  CBtnList,
} from '../../components/_index';
import infoChoice from '../../utils/info-choice';
import infoGet from '../../utils/info-get';

// markup
const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter;
  const tabData = ['全て', '施設', 'サービス', 'その他'];
  const [activeTab, setActivetab] = useState('全て');
  const facilitiesData = [
    {
      img: {
        src: '/assets/images/facilities/img_facility11.png',
        alt: '30周年記念ミュージアム',
      },
      category: '施設',
      title: <>30周年記念ミュージアム</>,
      link: {
        href: '/information/30thmusium/',
      },
    },
    {
      img: {
        src: '/assets/images/facilities/img_facility02.png',
        alt: 'ランドマークスパ',
      },
      category: '施設',
      title: <>ランドマークスパ</>,
      link: {
        href: '/facilities/landmark_spa/',
      },
    },
    {
      img: {
        src: '/assets/images/facilities/img_facility03.png',
        alt: '理容室',
      },
      category: '施設',
      title: <> 理容室</>,
      link: {
        href: '/facilities/barber/',
      },
    },

    {
      img: {
        src: '/assets/images/facilities/img_facility04.png',
        alt: ' フラワー＆ギフトショップ',
      },
      category: '施設',
      title: <>フラワー＆ギフトショップ</>,
      link: {
        href: '/facilities/flower/',
      },
    },
    {
      img: {
        src: '/assets/images/facilities/img_facility05.png',
        alt: 'アクセサリーブティック',
      },
      category: '施設',
      title: <>アクセサリーブティック</>,
      link: {
        href: '/facilities/shop/',
      },
    },
    {
      img: {
        src: '/assets/images/facilities/img_facility06.png',
        alt: 'Wi-fi無料サービス',
      },
      category: 'サービス',
      title: <>Wi-fi無料サービス</>,
      link: {
        href: '/facilities/business/',
      },
    },
    {
      img: {
        src: '/assets/images/facilities/img_facility07.png',
        alt: 'ベビーシッターサービス',
      },
      category: 'サービス',
      title: <>ベビーシッターサービス</>,
      link: {
        href: '/facilities/baby/',
      },
    },
    {
      img: {
        src: '/assets/images/facilities/img_facility08.png',
        alt: 'バリアフリーのご案内',
      },
      category: '各階',
      title: <>バリアフリーのご案内</>,
      link: {
        href: '/facilities/barrier_free/',
      },
    },
    {
      img: {
        src: '/assets/images/facilities/img_facility09.png',
        alt: '授乳室・おむつ替えコーナー',
      },
      category: '各階',
      title: <>授乳室・おむつ替えコーナー</>,
      link: {
        href: '/facilities/nursing_room/',
      },
    },
  ];
  const filterData = facilitiesData;
  return (
    <Layout>
      <SEO title={frontmatter?.title} description={frontmatter?.description} />
      <CJumbotron
        size="large"
        data={{
          title: {
            main: 'FACILITIES',
            sub: '館内施設・サービス',
          },
          imgList: [
            {
              img: {
                src: '/assets/images/facilities/kv.png',
              },
              imgSp: {
                src: '/assets/images/facilities/kv__sp.png',
              },
            },
          ],
        }}
      >
        <CBreadCrumb
          data={{
            parent: [],
            current: {
              label: '館内施設・サービス',
            },
          }}
        />
      </CJumbotron>
      <div className="l_sect">
        <LWrap>
          <CSectTitle title={{ ja: '館内施設・サービス', en: 'FACILITIES' }} />
          <CFixedImg
            width={1040}
            img={{
              src: '/assets/images/facilities/img_overview.png',
              alt: 'フロアのご案内',
            }}
          />
          <CFacilityCard data={filterData} />
        </LWrap>
      </div>
      <section className="l_sect">
        <LWrap>
          <CSectTitle title={{ ja: 'お知らせ', en: 'INFORMATION' }} />
          <CNewsList
            data={infoChoice(infoGet(), ['館内施設'], 5)}
            label={false}
          />
          <CBtnList
            data={[
              {
                label: 'お知らせ',
                link: {
                  href: '/information/',
                },
              },
            ]}
          />
        </LWrap>
      </section>
      <section className="u_mbMedium">
        <LWrap>
          <ul className="c_noteList">
            <li>画像は全てイメージです。</li>
          </ul>
        </LWrap>
      </section>
    </Layout>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
